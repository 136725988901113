import styled from 'styled-components';
import { StyledDiv } from '../../zz_general/utils/scss-modules/styled';
import { resetCss } from '../../zz_general/utils/scss-modules/reset';

export const Wrapper = styled(StyledDiv)`
  ${resetCss}

  button {
    all: unset;
    display: inline-block;
    cursor: pointer;
  }

  form {
    margin: 0;
  }

  * {
    box-sizing: border-box !important;
  }

  padding: 4vw;
  h1 {
    /* color: red;s */
    display: flex;
    justify-content: space-between;
  }
  span {
    font-size: 50%;
    line-height: 200%;
    cursor: pointer;
  }

  button {
    cursor: pointer;
    line-height: 18px;
    height: 26px;
    font-size: 18px;
    padding: 4px;
  }

  .searchArea {
    border: 1px solid black;
    border-radius: 4px;
    padding: 4px;

    button,
    input,
    select,
    textarea {
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      border: none;
      background: none;
      padding: 0;
      margin: 0;
      font: inherit;
      color: inherit;
      line-height: normal;
      outline: none;
      box-shadow: none;
      resize: none;
    }

    .title {
      width: 100%;
      padding: 4px;
      border-bottom: 1px solid gray;
      margin-bottom: 16px;
    }

    button {
      cursor: pointer;
      line-height: 18px;
      height: 34px;
      font-size: 18px;
      padding: 8px;
      margin-right: 4px;
      border-radius: 4px;
      color: white;
      outline: #161616 solid 1px;
      background-color: #161616;
      &:active {
        background-color: white;
        color: #161616;
        outline: #161616 solid 1px;
      }
    }
    .propertySelect {
      font-size: 18px;
      line-height: 18px;
      border: 1px solid black;
      border-radius: 4px;
      padding: 8px;
      position: relative;
    }
    .downArrow {
      position: absolute;
      background-color: black;
      width: 16px;
      height: 16px;
    }
    .valueInput {
      font-size: 18px;
      line-height: 18px;
      border: 1px solid black;
      border-radius: 4px;
      padding: 4px;
      margin-top: 4px;
      margin-right: 4px;
    }
    .methodSelect {
      font-size: 18px;
      line-height: 18px;
      border: 1px solid black;
      border-radius: 4px;
      padding: 8px;
      margin-top: 4px;
    }
    .searchButton {
      display: inline-block;
      cursor: pointer;
      line-height: 18px;
      height: 34px;
      font-size: 18px;
      padding: 8px;
      margin-top: 4px;
      border-radius: 4px;
      background-color: #161616;
      outline: #161616 solid 1px;
      color: white;
      &:active {
        background-color: white;
        color: black;
        outline: black solid 1px;
      }
    }
  }

  .csvArea {
    padding: 4px;
    border: 1px solid black;
    border-radius: 4px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center;
    .csvImportArea {
      border: 1px solid black;
      border-radius: 4px;
      padding: 4px;
      position: relative;
      width: 98%;
      text-align: center;
      .importLabel {
      }
      .importInput {
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        opacity: 0;
      }
    }
    .csvExportArea {
      border: 1px solid black;
      border-radius: 4px;
      padding: 4px;
      width: 98%;
      text-align: center;
      cursor: pointer;
      &.active {
        background-color: #161616;
        color: white;
      }
      &.passive {
        opacity: 0.5;
        background-color: gray;
        color: white;
      }
      .csvExportButton {
        text-align: center;
        width: 100%;
      }
    }
  }

  .tableArea {
    border: 1px solid black;
    border-radius: 4px;
    padding: 4px;

    .tableCon {
      overflow: scroll;
      height: 80vh;
      position: relative;

      .tableRow {
        width: 180vw;
        border-bottom: 1px solid black;
        display: grid;
        grid-template-columns: auto 1fr 3fr 1fr 1fr 1.5fr 1fr 1fr 3fr;
        &.selected {
          background-color: rgb(210, 236, 255);
        }
        &.header {
          position: sticky;
          background-color: white;
          top: 0;
          z-index: 1;
          &:hover {
            opacity: 1;
          }
        }
        &:hover {
          opacity: 0.7;
        }
        .checkbox {
          margin: 4px;
        }
        .tableSpan {
          line-height: 16px;
          height: 24px;
          font-size: 16px;
          display: block;
          padding: 4px;
        }
      }
      .warning {
        position: absolute;
        top: 25%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    button {
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      border: none;
      background: none;
      padding: 0;
      margin: 0;
      font: inherit;
      color: inherit;
      line-height: normal;
      outline: none;
      box-shadow: none;
      resize: none;
    }

    button {
      cursor: pointer;
      line-height: 18px;
      height: 34px;
      font-size: 18px;
      padding: 8px;
      margin-right: 4px;
      margin-bottom: 4px;
      border-radius: 4px;
      color: white;
      outline: #161616 solid 1px;
      background-color: #161616;
      &:active {
        background-color: white;
        color: #161616;
        outline: #161616 solid 1px;
      }
    }
  }

  .popUpWrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: grid;
    place-items: center;
    z-index: 2;
    .backGround {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 2;
    }
    .popUp {
      position: relative;
      z-index: 3;
      width: auto;
      max-width: 80vw;
      height: auto;
      background-color: white;
      padding: 10px;
      border-radius: 4px;
      border: 1px solid black;

      .title {
        text-align: center;
        display: block;
      }

      button {
        border-radius: 4px;
        background-color: #161616;
        color: white;
        width: 80%;
        margin: 4px auto;
        display: block;
        text-align: center;
      }
    }
  }

  .loadingWrap {
    position: fixed;
    background-color: rgba(255, 255, 255, 0.5);
    width: 100%;
    height: 100vh;
    z-index: 1;
    top: 0;
    left: 0;
    display: grid;
    place-items: center;

    .loading {
      width: 60%;
      height: 50%;
      display: grid;
      place-items: end center;
      background-color: white;
      border-radius: 4px;
      border: 1px solid black;
      padding: 10px;

      p {
        margin-top: 30px;
        place-self: start center;
      }
    }
  }
`;
