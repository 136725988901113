export const getDeviceInfo = () => {
  let deviceInfo = '';

  if (navigator.userAgentData) {
    const { brands, platform } = navigator.userAgentData;
    const brandInfo = brands.map((brand) => `${brand.brand} ${brand.version}`).join(', ');
    deviceInfo = `Platform: ${platform}, Browser: ${brandInfo}`;
  } else {
    const userAgent = navigator.userAgent;
    deviceInfo = `UserAgent: ${userAgent}`;
  }

  return deviceInfo;
};
