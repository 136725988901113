import dayjs from 'dayjs';

export const PopUp = ({ title, content, close, show }) => {
  return (
    <div className="popUpWrap" style={{ display: show ? 'grid' : 'none' }}>
      <div className="backGround" onClick={close}></div>
      <div className="popUp">
        <h1 className="title">{title}</h1>
        {content}
        <button onClick={close}>閉じる</button>
      </div>
    </div>
  );
};

export const ReservationsTable = ({ json, selectReservation }) => {
  const table = [];
  const header = (
    <div className="tableRow header">
      <input type="checkbox" style={{ opacity: '0' }} className="checkbox" />
      <span className="tableSpan">注文ID</span>
      <span className="tableSpan">氏名</span>
      <span className="tableSpan">伝票番号</span>
      <span className="tableSpan">発送状況</span>
      <span className="tableSpan">注文日時</span>
      <span className="tableSpan">配送希望日</span>
      <span className="tableSpan">配送希望時間</span>
      <span className="tableSpan">メールアドレス</span>
    </div>
  );
  for (const key in json) {
    const data = json[key].data;

    let reserveDate;
    if (typeof data.reserveDate === 'object') {
      reserveDate = convertToDayjs(data.reserveDate).format('YYYY/MM/DD HH:mm');
    } else {
      reserveDate = data.reserveDate;
    }

    let deliveryDate;
    const date = data.howToGet.receiveMethod[data.howToGet.flag].date;
    if (typeof date === 'object') {
      deliveryDate = convertToDayjs(date).format('YYYY/MM/DD');
    } else {
      deliveryDate = date;
    }

    table.push(
      <div
        key={key}
        onClick={() => {
          selectReservation(key, !json[key].selected);
        }}
        className={`tableRow ${json[key].selected ? 'selected' : ''}`}
      >
        <input type="checkbox" className="checkbox" readOnly checked={json[key].selected} />
        <span className="tableSpan">{key}</span>
        <span className="tableSpan">
          {data.lName + ' ' + data.fName + '(' + data.lNameKana + ' ' + data.fNameKana + ')'}
        </span>
        <span className="tableSpan">{data.voucherId}</span>
        <span className="tableSpan">{data.shippingStatus}</span>
        <span className="tableSpan">{reserveDate}</span>
        <span className="tableSpan">{deliveryDate}</span>
        <span className="tableSpan">{data.howToGet.receiveMethod[data.howToGet.flag].time}</span>
        <span className="tableSpan">{data.emailAddress}</span>
      </div>
    );
  }
  return (
    <div className="tableCon">
      {header}
      {table}
      {table.length === 0 && <div className="warning">該当するデータがありません</div>}
    </div>
  );
};

export const convertToDayjs = (timestamp) => {
  if (!timestamp) return dayjs();
  const seconds = timestamp.seconds || timestamp._seconds || 0;
  const nanoseconds = timestamp.nanoseconds || timestamp._nanoseconds || 0;
  const date = new Date(seconds * 1000 + nanoseconds / 1000000);
  return dayjs(date);
};

const timeOptions = {
  '8:00~12:00': '0812',
  '14:00~16:00': '1416',
  '16:00~18:00': '1618',
  '18:00~20:00': '1820',
  '19:00~21:00': '1921',
};

const enumerateItems = (items) => {
  const dictionaries = [];
  for (let key in items) {
    const data = items[key];
    if (data.count === 0) continue;
    dictionaries.push(data.yamato);
  }
  const integratedDict = dictionaries.reduce((acc, dict) => {
    for (const key in dict) {
      acc[key] = (acc[key] || 0) + dict[key];
    }
    return acc;
  }, {});
  let result = '';
  for (const key in integratedDict) {
    result += `${key}${integratedDict[key]} `;
  }
  return result.slice(0, -1);
};

export const generateCsv = (json) => {
  const rows = [];

  for (const key in json) {
    const data = json[key].data;
    const selected = json[key].selected;
    if (!selected) continue;

    const howToGet = data?.howToGet?.receiveMethod[data?.howToGet?.flag || ''] || '';
    const address = data?.address?.list[data?.address?.selected || 0] || '';
    const isGift = data?.address?.selected !== 0;
    let dataObj;
    let time;
    if (howToGet.date) {
      dataObj = convertToDayjs(howToGet.date);
    }
    if (howToGet.time) {
      time = timeOptions[howToGet.time];
    }

    const items = enumerateItems(data.items);

    rows.push({
      お客様管理番号: key,
      送り状種類: 0,
      クール区分: 1,
      伝票番号: '',
      出荷予定日: howToGet.date ? dataObj.subtract(3, 'day').format('YYYY/MM/DD') : '',
      お届け予定日: howToGet.date ? dataObj.format('YYYY/MM/DD') : '',
      配達時間帯: howToGet.time ? time : '',
      お届け先コード: '',
      お届け先電話番号: address?.phoneNum || '',
      お届け先電話番号枝番: '',
      お届け先郵便番号: address?.zipCode || '',
      お届け先住所: address?.prefecture + address?.city + address?.suburb + address?.street,
      お届け先アパートマンション名: address?.building || '',
      'お届け先会社・部門１': address?.corporation || '',
      'お届け先会社・部門２': '',
      お届け先名: address?.lastName + address?.firstName,
      'お届け先名(ｶﾅ)': '',
      敬称: '',
      ご依頼主コード: '',
      ご依頼主電話番号: isGift ? data?.phoneNumber || '' : '042-808-7933',
      ご依頼主電話番号枝番: '',
      ご依頼主郵便番号: isGift ? data?.address?.list[0]?.zipCode || '' : '197-0804',
      ご依頼主住所: isGift
        ? data?.address?.list[0]?.prefecture +
            data?.address?.list[0]?.city +
            data?.address?.list[0]?.suburb +
            data?.address?.list[0]?.street || ''
        : '東京都あきる野市秋川1-8',
      ご依頼主アパートマンション: isGift ? data?.address?.list[0]?.building || '' : 'あきる野ルピア1階',
      ご依頼主名: isGift ? data.lName + data.fName : 'BENE REGALO',
      'ご依頼主名(ｶﾅ)': '',
      品名コード１: '',
      品名１: 'ティラミス',
      品名コード２: '',
      品名２: items,
      荷扱い１: '',
      荷扱い２: '',
      記事: '',
      'ｺﾚｸﾄ代金引換額（税込)': '',
      内消費税額等: '',
      止置き: '',
      営業所コード: '',
      発行枚数: '',
      個数口表示フラグ: '',
      請求先顧客コード: '09033948027',
      請求先分類コード: '',
      運賃管理番号: '01',
      クロネコwebコレクトデータ登録: '',
      クロネコwebコレクト加盟店番号: '',
      クロネコwebコレクト申込受付番号１: '',
      クロネコwebコレクト申込受付番号２: '',
      クロネコwebコレクト申込受付番号３: '',
      お届け予定ｅメール利用区分: '',
      'お届け予定ｅメールe-mailアドレス': '',
      入力機種: '',
      お届け予定ｅメールメッセージ: '',
      お届け完了ｅメール利用区分: '',
      'お届け完了ｅメールe-mailアドレス': '',
      お届け完了ｅメールメッセージ: '',
      クロネコ収納代行利用区分: '',
      予備: '',
      '収納代行請求金額(税込)': '',
      収納代行内消費税額等: '',
      収納代行請求先郵便番号: '',
      収納代行請求先住所: '',
      '収納代行請求先住所（アパートマンション名）': '',
      '収納代行請求先会社・部門名１': '',
      '収納代行請求先会社・部門名２': '',
      '収納代行請求先名(漢字)': '',
      '収納代行請求先名(カナ)': '',
      '収納代行問合せ先名(漢字)': '',
      収納代行問合せ先郵便番号: '',
      収納代行問合せ先住所: '',
      '収納代行問合せ先住所（アパートマンション名）': '',
      収納代行問合せ先電話番号: '',
      収納代行管理番号: '',
      収納代行品名: '',
      収納代行備考: '',
      複数口くくりキー: '',
      検索キータイトル1: '',
      検索キー1: '',
      検索キータイトル2: '',
      検索キー2: '',
      検索キータイトル3: '',
      検索キー3: '',
      検索キータイトル4: '',
      検索キー4: '',
      // 入力時不要ここから
      検索キータイトル5: '',
      検索キー5: '',
      予備1: '',
      予備2: '',
      // 入力時不要ここまで
      投函予定メール利用区分: '',
      '投函予定メールe-mailアドレス': '',
      投函予定メールメッセージ: '',
      '投函完了メール（お届け先宛）利用区分': '',
      '投函完了メール（お届け先宛）e-mailアドレス': '',
      '投函完了メール（お届け先宛）メールメッセージ': '',
      '投函完了メール（ご依頼主宛）利用区分': '',
      '投函完了メール（ご依頼主宛）e-mailアドレス': '',
      '投函完了メール（ご依頼主宛）メールメッセージ': '',
    });
  }
  return rows;
};

export const generateCsv2 = (json) => {
  const rows = [];

  for (const key in json) {
    const data = json[key].data;
    const selected = json[key].selected;
    if (!selected) continue;

    const deliveryDate = data?.howToGet?.receiveMethod?.delivery?.date || '';
    const IsObject = typeof deliveryDate === 'object';

    const copiedData = {
      ...data,
      howToGet: {
        ...data.howToGet,
        receiveMethod: {
          ...data.howToGet.receiveMethod,
          delivery: {
            ...data.howToGet.receiveMethod.delivery,
            date: IsObject ? convertToDayjs(deliveryDate).format('YYYY/MM/DD') : deliveryDate,
          },
        },
      },
      reserveDate: convertToDayjs(data.reserveDate).format('YYYY/MM/DD HH:mm:ss'),
    };

    const flattenData = flattenObject(copiedData);

    rows.push({
      ...flattenData,
    });
  }

  const sortedRows = normalizeAndSortArrayOfObjects(rows);

  return sortedRows;
};

const flattenObject = (obj, parentKey = '', result = {}) => {
  for (const [key, value] of Object.entries(obj)) {
    const newKey = parentKey ? `${parentKey}-${key}` : key;

    if (typeof value === 'object' && value !== null && !Array.isArray(value)) {
      flattenObject(value, newKey, result);
    } else if (Array.isArray(value)) {
      value.forEach((item, index) => {
        flattenObject({ [index]: item }, newKey, result);
      });
    } else {
      result[newKey] = value;
    }
  }

  return result;
};

const normalizeAndSortArrayOfObjects = (arrayOfObjects) => {
  const allKeys = Array.from(new Set(arrayOfObjects.flatMap((obj) => Object.keys(obj)))).sort();

  return arrayOfObjects.map((obj) => {
    const normalizedObj = {};
    allKeys.forEach((key) => {
      normalizedObj[key] = obj[key] !== undefined ? obj[key] : '';
    });
    return normalizedObj;
  });
};

export const GenerateOptions = ({ start, end }) => {
  const options = [];
  for (let i = start; i <= end; i++) {
    options.push(
      <option key={i} value={i}>
        {i}
      </option>
    );
  }
  return options;
};
