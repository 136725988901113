import { useState, useEffect } from 'react';
import { validation, formatPhoneNum, insertTwoHyphens } from '../../utils/form';
import { InputForm } from '../../components/inputForm/inputForm';
import { LinkButton } from '../../components/linkButton';
import { useNavigate } from 'react-router-dom';

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { LPCustomerState, LPTakeoutState, LPPaymentState, LPFormState, LPtotalCountState } from '../../store/atoms';
import { collection, doc, setDoc } from 'firebase/firestore';
import { db } from '../../../zz_general/utils/configs/firebase';
import { generateUniqueID } from '../../utils/generateId';
import { importAll } from '../../utils/image';
import { Wrapper } from './scss';
import { Calender } from '../../components/calender2';
import { PullDown } from '../../components/pullDown';
import dayjs from 'dayjs';
import { FormHeader } from '../../components/fourStepBar';

export const LPCustomerInfoTakeout = () => {
  const image = importAll(require.context('./image', false, /\.(webp|svg)$/));
  const navigate = useNavigate();
  const totalCount = useRecoilValue(LPtotalCountState);
  const inputFormWidth = [340, 512, 720];
  const inputFormHalfWidth = [160, 246, 350];
  const inputFormHeight = [32, 38, 44];
  const linkButtonHeight = [48, 52, 60];
  const setPayment = useSetRecoilState(LPPaymentState);
  const [takeout, setTakeout] = useRecoilState(LPTakeoutState);
  const [recoilForm, setRecoilForm] = useRecoilState(LPFormState);
  const [isValid, setIsValid] = useState(recoilForm.takeoutFormIsValid);
  const [buttonIsLoading, setButtonIsLoading] = useState(false);
  const [customer, setCustomer] = useRecoilState(LPCustomerState);
  const [takeoutPlace, setTakeoutPlace] = useState(takeout?.place);
  const [takeoutDate, setTakeoutDate] = useState(takeout?.date);
  const [takeoutTime, setTakeoutTime] = useState(takeout?.time);
  const [showCalender, setShowCalender] = useState(false);
  const [form, setForm] = useState({
    lastName: recoilForm.content.lastName,
    firstName: recoilForm.content.firstName,
    lastNameKana: recoilForm.content.lastNameKana,
    firstNameKana: recoilForm.content.firstNameKana,
    mailAddress: recoilForm.content.mailAddress,
    phoneNum: recoilForm.content.phoneNum,
    takeoutPlace: recoilForm.content.takeoutPlace,
    takeoutDate: recoilForm.content.takeoutDate,
    takeoutTime: recoilForm.content.takeoutTime,
  });
  const setCustomerByProp = (key, value) => {
    setCustomer((current) => {
      const future = JSON.parse(JSON.stringify(current));
      future[key] = value;
      return future;
    });
  };
  const setFormByProp = (key, value) => {
    setForm((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };
  const setTakeoutByProp = (key, value) => {
    setTakeout((current) => {
      const future = JSON.parse(JSON.stringify(current));
      future[key] = value;
      return future;
    });
  };

  const onChangeInputForm = (event, prop, validationType) => {
    const value = event.target.value;
    setCustomerByProp(prop, value);
    if (!value) {
      setFormByProp(prop, 'not filled');
      setIsValid(false);
    } else if (validation[validationType].test(value)) {
      setFormByProp(prop, 'valid');
    } else {
      setFormByProp(prop, 'invalid');
      setIsValid(false);
    }
  };

  /**enter入力で次のフォームへ移動 */
  const onKeyUpInputForm = (event, currentProp, nextProp) => {
    if (form[currentProp] === 'valid' && event.keyCode === 13) {
      document.getElementById(nextProp).focus();
    }
  };

  /**ふりがなの自動入力 */
  const fillKanaAutomatically = (event, prop) => {
    const value = event.target.value;
    if (validation.hiragana.test(value)) {
      setCustomerByProp(prop + 'Kana', value);
      setFormByProp(prop + 'Kana', 'valid');
    }
  };

  // MEMO : 電話番号はハイフン等の整形があるので、個別で処理
  const onChangePhoneNum = (event) => {
    const format = formatPhoneNum(event);
    const shapedPhoneNum = insertTwoHyphens(event, format[0], format[1], format[2]);
    setCustomerByProp('phoneNum', shapedPhoneNum);
    const value = event.target.value.replaceAll('-', '').replaceAll(' ', '');
    if (!value) {
      setFormByProp('phoneNum', 'not filled');
      setIsValid(false);
    } else if (validation.phoneNum.test(value)) {
      setFormByProp('phoneNum', 'valid');
    } else {
      setFormByProp('phoneNum', 'invalid');
      setIsValid(false);
    }
  };

  // MEMO : inputのtypeをemailにするとspace入力時にonClickが発火しないため、onKeyUpで代替する
  const onKeyUpMailAddress = (event) => {
    const value = event.target.value;

    if (event.keyCode === 32) {
      setFormByProp('mailAddress', 'invalid');
      setIsValid(false);
    } else if (!value) {
      setFormByProp('mailAddress', 'not filled');
      setIsValid(false);
    } else if (validation.mail.test(value)) setFormByProp('mailAddress', 'valid');
  };

  // MEMO : フォーム全体が有効かどうかを判定する
  useEffect(() => {
    if (isValid) return;
    if (
      Object.keys(form).every(function (key) {
        return form[key] === 'valid';
      })
    ) {
      setIsValid(true);
    }
  }, [form]);

  useEffect(() => {
    if (!isValid) setButtonIsLoading(false);
  }, [isValid]);

  useEffect(() => {
    if (takeoutPlace === takeout?.place) return;
    if (takeoutPlace) {
      setTakeoutByProp('place', takeoutPlace);
      setTakeoutTime(null);
      setTakeoutByProp('time', null);
      setFormByProp('takeoutPlace', 'valid');
      setFormByProp('takeoutTime', 'not filled');
      setIsValid(false);
    }
  }, [takeoutPlace]);

  useEffect(() => {
    if (takeoutDate) {
      setTakeoutByProp('date', takeoutDate);
      setFormByProp('takeoutDate', 'valid');
      // console.log('not null');
    } else {
      setFormByProp('takeoutDate', 'not filled');
      setIsValid(false);
    }
  }, [takeoutDate]);

  useEffect(() => {
    if (takeoutTime === takeout?.time) return;
    if (takeoutTime) {
      setTakeoutByProp('time', takeoutTime);
      setFormByProp('takeoutTime', 'valid');
    }
  }, [takeoutTime]);

  useEffect(() => {
    // MEMO : 直接アクセスされた場合のリダイレクト
    if (totalCount === 0) {
      navigate('/');
    }
    // MEMO : フォームの有効性をセット
    if (
      Object.keys(form).every(function (key) {
        return form[key] === 'valid';
      })
    ) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
    // MEMO : 予約IDの生成
    const collectionRef = collection(
      db,
      'LP',
      process.env.REACT_APP_IS_PROD === 'true' ? 'LP' : 'LP-test',
      'reservations'
    );
    const id = generateUniqueID(collectionRef);
    id.then((res) => {
      // eslint-disable-next-line no-console
      console.log(res);
      setCustomerByProp('reservationId', res);
      const docRef = doc(collectionRef, res);
      setDoc(docRef, {});
      // MEMO : 送信済みフラグをリセット
      setPayment((current) => {
        const future = JSON.parse(JSON.stringify(current));
        future['payPay']['sentData'] = false;
        return future;
      });
    });
  }, []);

  return (
    <Wrapper
      inputFormWidth={inputFormWidth}
      inputFormHalfWidth={inputFormHalfWidth}
      inputFormHeight={inputFormHeight}
      linkButtonHeight={linkButtonHeight}
    >
      <FormHeader step={1} title="お客様情報" />
      <div className="formFlex">
        <InputForm
          className="marginBottom marginTop"
          required={true}
          title="お名前"
          help="姓名合わせて全角16文字まで"
          width={inputFormHalfWidth}
          height={inputFormHeight}
          placeholder="定良"
          description="姓"
          id="lastName"
          value={customer.lastName || ''}
          onChange={(e) => {
            onChangeInputForm(e, 'lastName', 'string');
            fillKanaAutomatically(e, 'lastName');
          }}
          onKeyUp={(e) => {
            onKeyUpInputForm(e, 'lastName', 'firstName');
          }}
          check={form.lastName === 'valid'}
          error={form.lastName === 'invalid'}
          errorMessage="姓に無効な文字や空白を含んでいます"
        />
        <div className="fName">
          <InputForm
            className="marginBottom marginTop"
            width={inputFormHalfWidth}
            height={inputFormHeight}
            placeholder="美鈴"
            description="名"
            id="firstName"
            value={customer.firstName || ''}
            onChange={(e) => {
              onChangeInputForm(e, 'firstName', 'string');
            }}
            onKeyUp={(e) => {
              onKeyUpInputForm(e, 'firstName', 'lastNameKana');
              fillKanaAutomatically(e, 'firstName');
            }}
            check={form.firstName === 'valid'}
            error={form.firstName === 'invalid'}
            errorMessage="名に無効な文字や空白を含んでいます"
          />
        </div>
      </div>
      <div className="formFlex">
        <InputForm
          className="marginBottom"
          required={true}
          title="ふりがな"
          help="せいめい合わせて全角25文字まで"
          width={inputFormHalfWidth}
          height={inputFormHeight}
          placeholder="ていら"
          description="せい"
          id="lastNameKana"
          value={customer.lastNameKana || ''}
          onChange={(e) => {
            onChangeInputForm(e, 'lastNameKana', 'hiragana');
          }}
          onKeyUp={(e) => {
            onKeyUpInputForm(e, 'lastNameKana', 'firstNameKana');
          }}
          check={form.lastNameKana === 'valid'}
          error={form.lastNameKana === 'invalid'}
          errorMessage="ひらがなで入力してください"
        />
        <div className="fName">
          <InputForm
            className="marginBottom"
            width={inputFormHalfWidth}
            height={inputFormHeight}
            placeholder="みすず"
            description="めい"
            id="firstNameKana"
            value={customer.firstNameKana || ''}
            onChange={(e) => {
              onChangeInputForm(e, 'firstNameKana', 'hiragana');
            }}
            onKeyUp={(e) => {
              onKeyUpInputForm(e, 'firstNameKana', 'mailAddress');
            }}
            check={form.firstNameKana === 'valid'}
            error={form.firstNameKana === 'invalid'}
            errorMessage="ひらがなで入力してください"
          />
        </div>
      </div>
      <InputForm
        className="marginBottom"
        required={true}
        title="メールアドレス"
        help="半角英数字記号のみ"
        width={inputFormWidth}
        height={inputFormHeight}
        placeholder="tiramisu@bene-regalo.com"
        id="mailAddress"
        type="email"
        value={customer.mailAddress || ''}
        onChange={(e) => {
          onChangeInputForm(e, 'mailAddress', 'mail');
        }}
        onKeyUp={(e) => {
          onKeyUpInputForm(e, 'mailAddress', 'phoneNum');
          onKeyUpMailAddress(e);
        }}
        check={form.mailAddress === 'valid'}
        error={form.mailAddress === 'invalid'}
        errorMessage="有効なメールアドレスを入力してください"
      />
      <div className="checkBoxWrap">
        <img
          src={image[`check_box_${customer.receiveDM ? 'active' : 'passive'}.svg`]}
          className="checkBox"
          onClick={() => {
            setCustomerByProp('receiveDM', !customer.receiveDM);
          }}
        />
        限定商品の案内などをメールで受け取る
      </div>
      <InputForm
        className="marginBottom"
        required={true}
        title="電話番号"
        help="半角数字のみ11桁まで"
        width={inputFormWidth}
        height={inputFormHeight}
        placeholder="090-0000-0000"
        description="ハイフン入力不要"
        id="phoneNum"
        type="tel"
        value={customer.phoneNum || ''}
        onChange={(e) => {
          onChangePhoneNum(e);
        }}
        onKeyUp={(e) => {
          onKeyUpInputForm(e, 'phoneNum', 'zipCode');
        }}
        check={form.phoneNum === 'valid'}
        error={form.phoneNum === 'invalid'}
        errorMessage="10~11桁の有効な電話番号を入力してください"
      />
      <PullDown
        className="marginBottom"
        description={takeoutPlace ? takeoutPlace : '-'}
        title="受取店舗"
        setItem={setTakeoutPlace}
      >
        <p>秋川店</p>
        <p>代々木店</p>
      </PullDown>
      <PullDown
        className="marginBottom"
        title="受取希望日"
        description={takeoutDate && dayjs(takeoutDate).format('YYYY年MM月DD日')}
        onClick={() => {
          setShowCalender(!showCalender);
        }}
      />
      {showCalender && (
        <Calender
          className="marginBottom"
          width={[320, 420, 480]}
          startDate={dayjs('2024-07-15')}
          endDate={dayjs('2024-08-13')}
          selectedDate={dayjs(takeoutDate)}
          setSelectedDate={(date) => {
            setTakeoutDate(date && date.format('YYYY-MM-DD'));
          }}
        />
      )}
      {takeoutPlace === '秋川店' && (
        <PullDown
          className=""
          title="受取希望時間"
          description={takeoutTime ? takeoutTime : '-'}
          setItem={setTakeoutTime}
        >
          <p>11:00~12:00</p>
          <p>12:00~13:00</p>
          <p>13:00~14:00</p>
          <p>14:00~15:00</p>
          <p>15:00~16:00</p>
          <p>16:00~17:00</p>
        </PullDown>
      )}
      {takeoutPlace === '代々木店' && (
        <PullDown
          className=""
          title="受取希望時間"
          description={takeoutTime ? takeoutTime : '-'}
          setItem={setTakeoutTime}
        >
          <p>11:00~12:00</p>
          <p>12:00~13:00</p>
          <p>13:00~14:00</p>
          <p>14:00~15:00</p>
          <p>15:00~16:00</p>
          <p>16:00~17:00</p>
          <p>17:00~18:00</p>
          <p>18:00~19:00</p>
        </PullDown>
      )}
      <LinkButton
        className="linkButton"
        width={inputFormWidth}
        height={linkButtonHeight}
        text="決済へ進む"
        color="black"
        active={isValid}
        loading={buttonIsLoading}
        onClick={() => {
          setButtonIsLoading(true);
          setRecoilForm({
            ...recoilForm,
            takeout_customerFormIsValid: isValid,
            content: {
              ...recoilForm.content,
              ...form,
            },
          });
          navigate('/takeout/payment');
        }}
      />
      <LinkButton
        width={inputFormWidth}
        height={linkButtonHeight}
        text="カートに戻る"
        color="white"
        arrow="left"
        active={true}
        onClick={() => {
          setButtonIsLoading(true);
          setRecoilForm({
            ...recoilForm,
            takeout_customerFormIsValid: isValid,
            content: {
              ...recoilForm.content,
              ...form,
            },
          });
          navigate('/lp-beta');
        }}
      />
    </Wrapper>
  );
};
