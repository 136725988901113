import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { LoadingWrapper, Wrapper } from './scss';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { isProd } from '../../zz_general/utils/snippets/env';
import { db } from '../../zz_general/utils/configs/firebase';
import { collection, doc, getDoc, getDocs, setDoc, updateDoc } from 'firebase/firestore';
import { CSVLink } from 'react-csv';
import { convertToDayjs, generateCsv, generateCsv2, GenerateOptions, PopUp, ReservationsTable } from './utils';
import Papa from 'papaparse';
import dayjs from 'dayjs';
import * as XLSX from 'xlsx';
import { generateUniqueID } from '../../zz_general/utils/snippets/generateId';

import { functions } from '../../zz_general/utils/configs/firebase';
import { httpsCallable } from 'firebase/functions';
import { Loader } from '../../zz_general/components/atoms/loader';

export const AdminShipping = () => {
  // #region Variables
  const auth = getAuth();
  const navigate = useNavigate();
  const [showPage, setShowPage] = useState(false);
  const [reservations, setReservations] = useState({});
  const [filteredReservations, setFilteredReservations] = useState({});
  const [filteringArgs, setFilteringArgs] = useState({});
  const [firestoreCsv, setFirestoreCsv] = useState([]);
  const [yamatoCsv, setYamatoCsv] = useState([]);
  const [importedCsvName, setImportedCsvName] = useState('');
  const [importedXlsxName, setImportedXlsxName] = useState('');
  const [isYamatoCsvReady, setIsYamatoCsvReady] = useState(false);
  const [isFirestoreCsvReady, setIsFirestoreCsvReady] = useState(false);
  const [popup, setPopup] = useState({ show: false, title: '', content: '' });
  const [loading, setLoading] = useState({
    show: false,
    content: '',
  });

  // #endregion
  // #region Functions
  const fetchReservations = async () => {
    try {
      const collectionRef = collection(db, 'LP', isProd ? 'LP' : 'LP-test', 'reservations');
      const docsRef = await getDocs(collectionRef);
      const reservations = {};
      docsRef.forEach((doc) => {
        const data = doc.data();
        const key = doc.id;
        reservations[key] = {
          data: data,
          selected: false,
        };
      });

      setReservations(reservations);
      filterReservations(reservations, filteringArgs.properties, filteringArgs.value, filteringArgs.method);
      reservations;
    } catch (e) {
      console.log(e);
      return;
    }
  };

  const filterReservations = async (reservations, properties = [], value = null, method = 'all') => {
    try {
      setIsYamatoCsvReady(false);
      setIsFirestoreCsvReady(false);
      const filteredReservations = {};
      for (const key in reservations) {
        const data = reservations[key].data;
        if (data.paymentStatus !== 'completed') continue;

        if (method === 'all') {
          filteredReservations[key] = reservations[key];
          continue;
        }

        let propertyValue = data;
        properties.forEach((property) => {
          propertyValue = propertyValue[property];
        });

        if (!propertyValue) {
          if (method === 'nonexistent') {
            filteredReservations[key] = reservations[key];
          }
          continue;
        }

        if (typeof value === 'string') {
          if (typeof propertyValue !== 'string') {
            continue;
          }
          if (method === 'exactMatch') {
            if (propertyValue === value) {
              filteredReservations[key] = reservations[key];
            }
          }
          if (method === 'partialMatch') {
            if (propertyValue.includes(value)) {
              filteredReservations[key] = reservations[key];
            }
          }
          if (method === 'notIncluded') {
            if (!propertyValue.includes(value)) {
              filteredReservations[key] = reservations[key];
            }
          }
          if (method === 'notMatch') {
            if (propertyValue !== value) {
              filteredReservations[key] = reservations[key];
            }
          }
        }

        if (typeof value === 'object') {
          propertyValue = convertToDayjs(propertyValue);
          if (method === 'dateAfter') {
            if (propertyValue.isAfter(value, 'day')) {
              filteredReservations[key] = reservations[key];
            }
          }
        }
      }
      setFilteredReservations(filteredReservations);
    } catch (e) {
      return;
    }
  };

  const selectReservation = (key, value) => {
    setIsYamatoCsvReady(false);
    setIsFirestoreCsvReady(false);
    setFilteredReservations({
      ...filteredReservations,
      [key]: {
        ...filteredReservations[key],
        selected: value,
      },
    });
  };

  const selectAll = (value) => {
    setIsYamatoCsvReady(false);
    setIsFirestoreCsvReady(false);
    const newFilteredReservations = {};
    for (const key in filteredReservations) {
      newFilteredReservations[key] = {
        ...filteredReservations[key],
        selected: value,
      };
    }
    setFilteredReservations(newFilteredReservations);
  };

  const updateShippingStatus = async (key, value) => {
    try {
      const collectionRef = collection(db, 'LP', isProd ? 'LP' : 'LP-test', 'reservations');
      const docRef = doc(collectionRef, key);
      await updateDoc(docRef, {
        shippingStatus: value,
      });
    } catch (e) {
      setPopup({
        show: true,
        title: 'エラー',
        content: `発送ステータスの更新で、エラーが発生しました。${e}`,
      });
      setLoading({
        show: false,
        content: '',
      });
      return;
    }
  };

  const sendEmail = async (reservationId) => {
    const LPShippingSendEmail = httpsCallable(
      functions,
      process.env.REACT_APP_IS_PROD === 'true' ? 'LPShipping-sendEmail' : 'LPShippingTest-sendEmail'
    );
    return await LPShippingSendEmail(reservationId);
  };

  const updateAll = async (value) => {
    setLoading({
      show: true,
      content: '発送ステータスを更新しています。',
    });
    let count = 0;
    for (const key in filteredReservations) {
      if (!filteredReservations[key].selected) continue;
      if (filteredReservations[key].data.shippingStatus === value) continue;
      await updateShippingStatus(key, value);
      if (value === 'completed') {
        await sendEmail(key);
      }
      count++;
    }
    if (count === 0) {
      setPopup({
        show: true,
        title: 'エラー',
        content: '選択された注文が無いか、発送ステータスがすでに更新されています。',
      });
      setLoading({
        show: false,
        content: '',
      });
      return;
    }
    setLoading({
      show: false,
      content: '',
    });
    setPopup({
      show: true,
      title: '完了',
      content: '発送ステータスを更新しました',
    });

    await fetchReservations();
  };

  const importYamatoCsv = (event) => {
    try {
      const file = event.target.files[0];
      if (file) {
        setLoading({
          show: true,
          content: 'CSVファイルをインポートしています。',
        });
        setImportedCsvName(file.name);
        const reader = new FileReader();
        reader.onload = (e) => {
          const text = new TextDecoder('shift-jis').decode(e.target.result);
          Papa.parse(text, {
            header: true,
            skipEmptyLines: true,
            complete: (result) => {
              console.log('Parsed Data:', result.data);
              registerVoucherId(result.data);
            },
            error: (error) => {
              console.error('Error parsing CSV:', error);
              setPopup({
                show: true,
                title: 'エラー',
                content: 'CSVファイルの読み込みでエラーが発生しました',
              });
              setLoading({
                show: false,
                content: '',
              });
            },
          });
        };
        reader.readAsArrayBuffer(file);
      }
    } catch (e) {
      console.log('エラー : ', e);
      setPopup({
        show: true,
        title: 'エラー',
        content: `ファイルのインポートで、エラーが発生しました。${e}`,
      });
    }
  };

  const registerVoucherId = async (yamatoCsv) => {
    try {
      const reservationsList = [];
      setLoading({
        show: true,
        content: '伝票番号を登録しています。',
      });
      for (const data of yamatoCsv) {
        const reservationsId = data['お客様管理番号'];
        const voucherId = data['伝票番号'];
        reservationsList.push(reservationsId);
        if (!reservationsId || !voucherId) continue;

        const collectionRef = collection(db, 'LP', isProd ? 'LP' : 'LP-test', 'reservations');
        const docRef = doc(collectionRef, reservationsId);
        const docSnap = await getDoc(docRef);

        if (!docSnap.exists()) {
          console.log('ドキュメントが存在しません : ', reservationsId);
          setPopup({
            show: true,
            title: 'エラー',
            content: `注文ID ${reservationsId} が存在しません。`,
          });
          setLoading({
            show: false,
            content: '',
          });
          return;
        }

        await updateDoc(docRef, {
          voucherId: voucherId,
        });
        console.log('伝票番号を保存しました : ', reservationsId);
      }

      setLoading({
        show: true,
        content: '注文情報を再取得しています。',
      });
      await fetchReservations();
      const fetchedReservations = await fetchReservationsFromList(reservationsList);
      setFilteredReservations(fetchedReservations);

      console.log('伝票番号の登録が完了しました');
      setLoading({
        show: false,
        content: '',
      });
    } catch (e) {
      console.log('エラー : ', e);
      setPopup({
        show: true,
        title: 'エラー',
        content: `伝票番号の登録で、エラーが発生しました。${e}`,
      });
      setLoading({
        show: false,
        content: '',
      });
      return;
    }
  };

  const importGiftXlsx = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImportedXlsxName(file.name);
      const reader = new FileReader();
      reader.onload = (event) => {
        const arrayBuffer = event.target.result;
        const workbook = XLSX.read(arrayBuffer, { type: 'array' });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        processCells(jsonData);
      };
      reader.readAsArrayBuffer(file);
    }
  };

  const processCells = async (sheetData) => {
    setLoading({
      show: true,
      content: 'xlsxファイルをインポートしています。',
    });
    let customerData = {};
    let importedReservations = {};

    try {
      for (const [rowIndex, row] of sheetData.entries()) {
        if (rowIndex === 5) {
          const res = await searchPostalCode(row[2]);
          customerData = {
            name: row[0] || '',
            companyName: row[1] || '',
            zipCode: row[2] || '',
            prefecture: res.prefecture,
            city: res.city,
            suburb: res.suburb,
            street: row[4] || '',
            building: row[5] || '',
            phoneNumber: row[6] || '',
            mail: row[7] || '',
          };
        }
        if (rowIndex >= 10) {
          if (row.length === 0) continue;
          if (isEmptyCell(row[0])) continue;
          const collectionRef = collection(db, 'LP', isProd ? 'LP' : 'LP-test', 'reservations');
          const res = await searchPostalCode(row[2]);
          const id = await generateUniqueID(collectionRef, 6);
          const excelDate = row[8] || '';
          const jsDate = excelDateToJSDate(excelDate);
          const itemName = row[7] || '';
          let items = {};
          if (itemName === 'Primo Tiramisu in Box') {
            items = {
              uid1: {
                name: 'Primo Tiramisu in box',
                description: 'Tiramisu in box',
                yamato: { Pﾎﾞ: 1 },
                price: 5000,
                count: row[10] || '',
                fileName: 'BENE_REGALO_Tiramisu_in_box.webp',
                fileName2: 'BENE_REGALO_Tiramisu_in_box.avif',
                disabled: false,
                limit: 2,
              },
            };
          }
          if (itemName === 'Tiramisu Financier Box') {
            items = {
              uid4: {
                name: 'Tiramisu Financier ×10',
                description: 'Financier ×10',
                yamato: { ﾌｲﾅ: 10 },
                price: 2500,
                count: row[10] || '',
                fileName: 'BENE_REGALO_Tiramisu_Financier_10.webp',
                fileName2: 'BENE_REGALO_Tiramisu_Financier_10.avif',
                disabled: false,
                limit: null,
              },
            };
          }
          const reservationData = {
            reservationsId: id,
            // 顧客情報
            fName: '',
            lName: customerData.name,
            emailAddress: customerData.mail,
            phoneNumber: customerData.phoneNumber,
            // 住所情報
            address: {
              list: [
                {
                  building: customerData.building,
                  city: customerData.city,
                  corporation: customerData.companyName,
                  firstName: '',
                  firstNameKana: '',
                  lastNameKana: '',
                  lastName: customerData.name,
                  phoneNum: customerData.phoneNumber,
                  prefecture: customerData.prefecture,
                  street: customerData.street,
                  suburb: customerData.suburb,
                  zipCode: customerData.zipCode,
                },
                {
                  building: row[5] || '',
                  city: res.city,
                  corporation: row[1] || '',
                  firstName: '',
                  firstNameKana: '',
                  lastNameKana: '',
                  lastName: row[0] || '',
                  phoneNum: row[6] || '',
                  prefecture: res.prefecture,
                  street: row[4] || '',
                  suburb: res.suburb,
                  zipCode: row[2] || '',
                },
              ],
              selected: 1,
            },
            // 商品情報
            items: items,
            total: {
              totalCount: row[10] || '',
              totalPrice: row[11] || '',
            },
            // 受取情報
            voucherId: 'unregistered',
            shippingStatus: 'pending',
            howToGet: {
              flag: 'delivery',
              receiveMethod: {
                delivery: {
                  status: 'no',
                  time: row[9] || '',
                  date: jsDate,
                },
              },
            },
            // メール送信を行ったか
            mailStatus: '',
            // 予約確認URL
            url: `https://bene-regalo.com/delivery/customer-page?reservationsId=${id}`,
            // 予約日時
            reserveDate: new Date(),
            // 支払い状況
            paymentStatus: 'completed',
          };
          importedReservations[id] = {
            data: reservationData,
          };
        }
      }
    } catch (e) {
      console.log('エラー : ', e);
      setPopup({
        show: true,
        title: 'エラー',
        content: `xlsxの読み込みで、エラーが発生しました。${e}`,
      });
      setLoading({
        show: false,
        content: '',
      });
      return;
    }

    console.log(importedReservations);
    setLoading({
      show: true,
      content: '注文情報を生成しています。',
    });
    await createReservationDocs(importedReservations);
    setLoading({
      show: true,
      content: '顧客情報を生成しています。',
    });
    const reservationsList = [];
    for (const key in importedReservations) {
      reservationsList.push(key);
    }
    await createCustomerDoc(customerData, reservationsList);
    setLoading({
      show: true,
      content: '注文情報を再取得しています。',
    });
    await fetchReservations();

    const fetchedReservations = await fetchReservationsFromList(reservationsList);
    setFilteredReservations(fetchedReservations);

    const yamatoData = generateCsv(fetchedReservations);
    if (yamatoData.length === 0) {
      setPopup({
        show: true,
        title: 'エラー',
        content: '注文データがありません',
      });
      setLoading({
        show: false,
        content: '',
      });
      return;
    }
    setYamatoCsv(yamatoData);
    setIsYamatoCsvReady(true);

    console.log('注文情報を更新しました');
    setLoading({
      show: false,
      content: '',
    });
  };

  const searchPostalCode = async (postCode) => {
    const url = 'https://postcode.teraren.com/postcodes/' + postCode + '.json';
    const response = await fetch(url)
      .then((response) => response.json())
      .then((json) => {
        return json;
      })
      .catch(() => {
        return null;
      });
    return response;
  };

  const excelDateToJSDate = (serial) => {
    const excelBaseDate = new Date(1900, 0, 1);
    const realSerial = serial - 1;
    const msPerDay = 24 * 60 * 60 * 1000;
    const leapYearBugOffset = realSerial > 59 ? -1 : 0;
    return new Date(excelBaseDate.getTime() + (realSerial + leapYearBugOffset) * msPerDay);
  };

  const isEmptyCell = (cell) => {
    return cell === null || cell === undefined || (typeof cell === 'object' && Object.keys(cell).length === 0);
  };

  const createReservationDocs = async (importedReservations) => {
    for (const key in importedReservations) {
      try {
        const collectionRef = collection(db, 'LP', isProd ? 'LP' : 'LP-test', 'reservations');
        const docRef = doc(collectionRef, key);

        await setDoc(docRef, {
          ...importedReservations[key].data,
        });
        console.log('注文ドキュメントを作成しました : ', key);
      } catch (e) {
        console.log('エラー : ', e);
        setPopup({
          show: true,
          title: 'エラー',
          content: `注文データの生成で、エラーが発生しました。${e}`,
        });
        setLoading({
          show: false,
          content: '',
        });
        return;
      }
    }
  };

  const fetchReservationsFromList = async (reservationsList) => {
    let reservations = {};
    try {
      for (const reservationId of reservationsList) {
        const collectionRef = collection(db, 'LP', isProd ? 'LP' : 'LP-test', 'reservations');
        const docRef = doc(collectionRef, reservationId);
        const docSnap = await getDoc(docRef);
        if (!docSnap.exists()) {
          console.log('ドキュメントが存在しません : ', reservationId);
          setPopup({
            show: true,
            title: 'エラー',
            content: `注文ID ${reservationId} が存在しません。`,
          });
          setLoading({
            show: false,
            content: '',
          });
          return;
        }
        reservations[reservationId] = {
          data: docSnap.data(),
          selected: true,
        };
      }
      return reservations;
    } catch (e) {
      console.log('エラー : ', e);
      setPopup({
        show: true,
        title: 'エラー',
        content: `注文データの取得およびヤマト伝票csvの生成で、エラーが発生しました。${e}`,
      });
      setLoading({
        show: false,
        content: '',
      });
      return;
    }
  };

  const createCustomerDoc = async (customerData, reservationsList) => {
    try {
      const collectionRef = collection(db, 'LP', isProd ? 'LP' : 'LP-test', 'customers');
      const id = await generateUniqueID(collectionRef, 6);
      const docRef = doc(collectionRef, id);

      await setDoc(docRef, {
        ...customerData,
        reservations: reservationsList,
      });
      console.log('顧客ドキュメントを作成しました : ', id);
    } catch (e) {
      console.log('エラー : ', e);
      setPopup({
        show: true,
        title: 'エラー',
        content: `顧客データの生成で、エラーが発生しました。${e}`,
      });
      setLoading({
        show: false,
        content: '',
      });
      return;
    }
  };

  // #endregion
  // #region UseEffects
  useEffect(() => {
    fetchReservations();

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        if (user.uid !== process.env.REACT_APP_ADMIN_UID) {
          navigate('/admin/sign-in');
        }
      } else {
        navigate('/admin/sign-in');
      }
      setShowPage(true);
    });
    return () => unsubscribe();
  }, []);
  // #endregion
  if (!showPage) return <></>;

  return (
    <Wrapper>
      <h1>
        伝票登録{' '}
        <span
          onClick={() => {
            signOut(auth);
          }}
        >
          ログアウト
        </span>
      </h1>
      <br />
      <div className="searchArea">
        <div className="title">検索条件</div>
        <select
          value={filteringArgs?.properties || ''}
          onChange={(e) => {
            if (e.target.value === '') {
              setFilteringArgs({});
              return;
            }
            setFilteringArgs({
              ...filteringArgs,
              properties: [e.target.value],
              method: '',
              value: '',
            });
          }}
          className="propertySelect"
        >
          <option value="">選択してください</option>
          <option value="reservationsId">注文ID</option>
          <option value="voucherId">伝票番号</option>
          <option value="shippingStatus">発送ステータス</option>
          <option value="reserveDate">注文日時</option>
        </select>
        <br />
        {Object.keys(filteringArgs).length > 0 && (
          <>
            {(filteringArgs.properties[0] === 'reservationsId' ||
              filteringArgs.properties[0] === 'voucherId' ||
              filteringArgs.properties[0] === 'shippingStatus') && (
              <div>
                {(filteringArgs.method === 'exactMatch' ||
                  filteringArgs.method === 'partialMatch' ||
                  filteringArgs.method === 'notMatch' ||
                  filteringArgs.method === 'notIncluded') && (
                  <input
                    value={filteringArgs.value}
                    onChange={(e) => {
                      setFilteringArgs({
                        ...filteringArgs,
                        value: e.target.value,
                      });
                    }}
                    className="valueInput"
                  />
                )}
                <select
                  value={filteringArgs.method || ''}
                  onChange={(e) => {
                    setFilteringArgs({
                      ...filteringArgs,
                      method: e.target.value,
                      value: '',
                    });
                  }}
                  className="methodSelect"
                >
                  <option value="">選択してください</option>
                  <option value="exactMatch">完全一致</option>
                  <option value="partialMatch">部分一致</option>
                  <option value="notMatch">一致しない</option>
                  <option value="notIncluded">含まない</option>
                  <option value="nonexistent">存在しない</option>
                </select>
                <br />
              </div>
            )}
            {filteringArgs.properties[0] === 'reserveDate' && (
              <div>
                {(filteringArgs.method === 'exactMatch' ||
                  filteringArgs.method === 'partialMatch' ||
                  filteringArgs.method === 'notMatch' ||
                  filteringArgs.method === 'notIncluded') && (
                  <input
                    onChange={(e) => {
                      setFilteringArgs({
                        ...filteringArgs,
                        value: e.target.value,
                      });
                    }}
                    className="valueInput"
                  />
                )}
                {filteringArgs.method === 'dateAfter' && (
                  <>
                    <select
                      className="methodSelect"
                      value={filteringArgs.value ? filteringArgs.value.year() : '2023'}
                      onChange={(e) => {
                        setFilteringArgs({
                          ...filteringArgs,
                          value: filteringArgs.value.set('year', parseInt(e.target.value)),
                        });
                      }}
                    >
                      <GenerateOptions start={2020} end={2026} />
                    </select>
                    年
                    <select
                      className="methodSelect"
                      value={filteringArgs.value ? filteringArgs.value.month() + 1 : '1'}
                      onChange={(e) => {
                        setFilteringArgs({
                          ...filteringArgs,
                          value: filteringArgs.value.set('month', parseInt(e.target.value) - 1),
                        });
                      }}
                    >
                      <GenerateOptions start={1} end={12} />
                    </select>
                    月
                    <select
                      className="methodSelect"
                      value={filteringArgs.value ? filteringArgs.value.date() : '1'}
                      onChange={(e) => {
                        setFilteringArgs({
                          ...filteringArgs,
                          value: filteringArgs.value.set('date', parseInt(e.target.value)),
                        });
                      }}
                    >
                      <GenerateOptions start={1} end={31} />
                    </select>
                    日
                  </>
                )}
                <select
                  value={filteringArgs.method || ''}
                  onChange={(e) => {
                    if (e.target.value === 'dateAfter') {
                      setFilteringArgs({
                        ...filteringArgs,
                        method: e.target.value,
                        value: dayjs(),
                      });
                      return;
                    }
                    setFilteringArgs({
                      ...filteringArgs,
                      method: e.target.value,
                    });
                  }}
                  className="methodSelect"
                >
                  <option value="">選択してください</option>
                  <option value="exactMatch">完全一致</option>
                  <option value="partialMatch">部分一致</option>
                  <option value="notMatch">一致しない</option>
                  <option value="notIncluded">含まない</option>
                  <option value="nonexistent">存在しない</option>
                  <option value="dateAfter">より新しい</option>
                </select>
                <br />
              </div>
            )}
          </>
        )}
        <div
          onClick={() => {
            filterReservations(reservations, filteringArgs.properties, filteringArgs.value, filteringArgs.method);
          }}
          className="searchButton"
        >
          検索する
        </div>
      </div>
      <br />
      <div className="searchArea">
        <div className="title">簡易検索</div>
        <button
          onClick={() => {
            filterReservations(reservations);
            setFilteringArgs({});
          }}
        >
          全件表示
        </button>
        <button
          onClick={() => {
            filterReservations(reservations, ['voucherId'], 'unregistered', 'exactMatch');
            setFilteringArgs({ properties: ['voucherId'], value: 'unregistered', method: 'exactMatch' });
          }}
        >
          伝票未登録
        </button>
        <button
          onClick={() => {
            filterReservations(reservations, ['voucherId'], 'unregistered', 'notMatch');
            setFilteringArgs({ properties: ['voucherId'], value: 'unregistered', method: 'notMatch' });
          }}
        >
          伝票登録済み
        </button>
        <button
          onClick={() => {
            filterReservations(reservations, ['shippingStatus'], 'pending', 'exactMatch');
            setFilteringArgs({ properties: ['shippingStatus'], value: 'pending', method: 'exactMatch' });
          }}
        >
          未発送
        </button>
        <button
          onClick={() => {
            filterReservations(reservations, ['shippingStatus'], 'completed', 'exactMatch');
            setFilteringArgs({ properties: ['shippingStatus'], value: 'completed', method: 'exactMatch' });
          }}
        >
          発送済み
        </button>
      </div>
      <br />
      <p>ヤマト</p>
      <div className="csvArea">
        <div className="csvImportArea">
          <label htmlFor="fileInput" className="importLabel">
            {!importedCsvName ? (
              <div>ヤマト発行済みデータCSVをインポート</div>
            ) : (
              <>インポートしました：{importedCsvName}</>
            )}
          </label>
          <input type="file" accept=".csv" onChange={importYamatoCsv} id="fileInput" className="importInput" />
        </div>

        <div className={isYamatoCsvReady ? 'csvExportArea active' : 'csvExportArea passive'}>
          {isYamatoCsvReady ? (
            <CSVLink
              data={yamatoCsv}
              filename={`${dayjs().format('YYYYMMDD_HHmm')}.csv`}
              className="csvExportButton"
              target="_blank"
            >
              ヤマト伝票CSVをエクスポート
            </CSVLink>
          ) : (
            <div className="csvExportButton">ヤマト伝票CSVをエクスポート</div>
          )}
        </div>
      </div>
      <p>お歳暮・お中元</p>
      <div className="csvArea">
        <div className="csvImportArea">
          <label htmlFor="fileInput" className="importLabel">
            {!importedXlsxName ? (
              <div>配送リスト（XLSX）をインポート</div>
            ) : (
              <>インポートしました：{importedXlsxName}</>
            )}
          </label>
          <input type="file" accept=".xlsx, .xls" onChange={importGiftXlsx} id="fileInput" className="importInput" />
        </div>

        {/* <div className={isFirestoreCsvReady ? 'csvExportArea active' : 'csvExportArea passive'}>
          {isFirestoreCsvReady ? (
            <CSVLink
              data={firestoreCsv}
              filename={`${dayjs().format('YYYYMMDD_HHmm')}.csv`}
              className="csvExportButton"
              target="_blank"
            >
              注文データ（CSV）をエクスポート
            </CSVLink>
          ) : (
            <div className="csvExportButton">注文データ（CSV）をエクスポート</div>
          )}
        </div> */}
      </div>
      <br />
      <br />
      <div className="tableArea">
        <button
          onClick={() => {
            selectAll(true);
          }}
        >
          全選択
        </button>
        <button
          onClick={() => {
            selectAll(false);
          }}
        >
          全解除
        </button>

        <br />
        <button
          onClick={() => {
            const yamatoData = generateCsv(filteredReservations);
            if (yamatoData.length === 0) {
              setPopup({
                show: true,
                title: 'エラー',
                content: '選択された注文がありません',
              });
              return;
            }
            setYamatoCsv(yamatoData);
            setIsYamatoCsvReady(true);

            const firestoreData = generateCsv2(filteredReservations);
            if (firestoreData.length === 0) {
              setPopup({
                show: true,
                title: 'エラー',
                content: '選択された注文がありません',
              });
              return;
            }
            setFirestoreCsv(firestoreData);
            setIsFirestoreCsvReady(true);
          }}
        >
          選択中の注文をCSVに変換
        </button>
        <button
          onClick={() => {
            updateAll('completed');
          }}
        >
          選択中の注文を発送済みに変更
        </button>
        <button
          onClick={() => {
            updateAll('pending');
          }}
        >
          選択中の注文を未発送に変更
        </button>
        <br />
        <br />
        <ReservationsTable json={filteredReservations} selectReservation={selectReservation} />
      </div>
      <PopUp
        title={popup.title}
        content={popup.content}
        close={() => {
          setPopup({ show: false, title: '', content: '' });
        }}
        show={popup.show}
      />
      {loading.show && (
        <div className="loadingWrap">
          <div className="loading">
            <Loader color="#161C1C" size={[32, 36, 40]} />
            <p>{loading.content}</p>
          </div>
        </div>
      )}
    </Wrapper>
  );
};
