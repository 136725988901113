import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { Wrapper } from './scss';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

export const AdminItem = () => {
  // #region Variables
  const auth = getAuth();
  const navigate = useNavigate();
  const [showPage, setShowPage] = useState(false);

  // #endregion
  // #region Functions

  // #endregion
  // #region UseEffects
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        if (user.uid !== process.env.REACT_APP_ADMIN_UID) {
          navigate('/admin/sign-in');
        }
      } else {
        navigate('/admin/sign-in');
      }
      setShowPage(true);
    });

    return () => unsubscribe();
  }, []);
  // #endregion
  if (!showPage) return <></>;
  return (
    <Wrapper>
      <h1>
        item管理{' '}
        <span
          onClick={() => {
            signOut(auth);
          }}
        >
          ログアウト
        </span>
      </h1>
    </Wrapper>
  );
};
