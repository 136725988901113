import { useEffect, useState } from 'react';
import classNames from 'classnames';
import css from './index.module.scss';

// MEMO: images
import { importAll } from '../utils/image';

import logo from './image/logo.webp';
import logoText from './image/logoText.webp';
import deleteIcon from './image/deleteIcon.png';
import sideMenuIconChecked from './image/sideMenuIconChecked.webp';
import sideMenuIcon from './image/sideMenuIcon.webp';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

export function LPHeader() {
  const navigate = useNavigate();
  return (
    <header className={css.header}>
      <div className={css.imageBox}>
        <img
          src={logo}
          className={css.logo}
          alt="logo"
          onClick={() => {
            navigate('/');
          }}
        />
        <img
          src={logoText}
          className={css.logoText}
          alt="logo"
          onClick={() => {
            navigate('/');
          }}
        />
      </div>
    </header>
  );
}

export function SideMenu(props) {
  const [isChecked, setIsChecked] = useState(false);

  const handleIsChecked = () => {
    if (isChecked) {
      setIsChecked(false);
    } else {
      setIsChecked(true);
    }
  };

  return (
    <div className={css.sideMenu}>
      <input type="checkbox" id="openSidebarMenu" onChange={() => handleIsChecked()} />
      <label htmlFor="openSidebarMenu" className={css.sidebarIconToggle}>
        <img
          src={sideMenuIconChecked}
          className={classNames(!isChecked && css.display_false)}
          alt="sideMenuIconChecked"
        />
        <img src={sideMenuIcon} className={classNames(isChecked && css.display_false)} alt="sideMenuIcon" />
      </label>

      <div className={css.sidebarMenu}>
        <ul className={css.sidebarMenuInner}>{props.children}</ul>
      </div>
    </div>
  );
}

export function ErrModal(props) {
  return (
    <div className={css.errModal}>
      <div
        // onClick={props.updateSetIsErrModal(false)}
        onClick={props.close()}
        className={css.ModalBack}
      >
        <div className={css.Modal}>
          <div className={css.iconBox}>
            <img src={deleteIcon} className={css.deleteIcon} alt="deleteIcon" />
          </div>
          <div className={css.textBox}>
            <div className={css.title}>
              <p>本当に削除しますか？</p>
            </div>

            <div className={css.description}>
              <p>
                削除すると元に戻せなくなります。 <br />
                同じ商品をご購入の際は、選びおなしてください。
                <br />
                本当に削除してよろしいですか？
              </p>
            </div>
          </div>

          <div className={css.buttonBox}>
            <button className={css.deleteButton} onClick={() => {}}>
              削除する
            </button>

            <button className={css.cancelButton} onClick={() => {}}>
              {'< やめる'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export function CustomerInfo(data) {
  const image = importAll(require.context('../delivery/confirm/image', false, /\.(webp|svg)$/));
  const customer = data.customer;
  return (
    <div className={css.customerInfoSection}>
      <div className={css.title}>お客様情報</div>
      <div className={classNames(css.customerInfo)}>
        <img src={image['customer.svg']} />
        <span className={css.text}>
          {customer.familyName} {customer.lastName}（{customer.familyNameKana} {customer.lastNameKana}）
        </span>
      </div>
      <div className={classNames(css.customerInfo)}>
        <img src={image['mail.svg']} />
        <span className={css.text}>{customer.mailAddress}</span>
      </div>
      <div className={classNames(css.customerInfo)}>
        <img src={image['phone.svg']} />
        <span className={css.text}>{customer.phoneNum}</span>
      </div>
    </div>
  );
}

export function CustomerInfo_takeout(data) {
  const image = importAll(require.context('../delivery/confirm/image', false, /\.(webp|svg)$/));
  const customer = data.customer;
  const takeout = data.takeout;
  return (
    <div className={css.customerInfoSection}>
      <div className={css.title}>お客様情報</div>
      <div className={classNames(css.customerInfo)}>
        <img src={image['customer.svg']} />
        <span className={css.text}>
          {customer.lastName} {customer.firstName}（{customer.lastNameKana} {customer.firstNameKana}）
        </span>
      </div>
      <div className={classNames(css.customerInfo)}>
        <img src={image['place.svg']} />
        <div className={classNames(css.text)}>お受け取り店舗：{takeout.place}</div>
      </div>
      <div className={classNames(css.customerInfo)}>
        <div className={classNames(css.customerInfo)}>
          <img src={image['calender.svg']} />
          <div className={classNames(css.text)}>
            期日：{takeout?.date && dayjs(takeout.date).format('YYYY年MM月DD日')}
            {customer.takeoutTime}
          </div>
        </div>
      </div>
      <div className={classNames(css.customerInfo)}>
        <img src={image['mail.svg']} />
        <span className={css.text}>{customer.mailAddress}</span>
      </div>
      <div className={classNames(css.customerInfo)}>
        <img src={image['phone.svg']} />
        <span className={css.text}>{customer.phoneNum}</span>
      </div>
    </div>
  );
}

export function CustomerInfo_delivery(data) {
  const image = importAll(require.context('../delivery/confirm/image', false, /\.(webp|svg)$/));
  const customer = data.customer;
  const address = data.address.list[0];
  if (!address) return null;
  return (
    <div className={css.customerInfoSection}>
      <div className={css.title}>お客様情報</div>
      <div className={classNames(css.customerInfo)}>
        <img src={image['customer.svg']} />
        <span className={css.text}>
          {customer.lastName} {customer.firstName}（{customer.lastNameKana} {customer.firstNameKana}）
        </span>
      </div>
      <div className={classNames(css.text)}>
        <div className={classNames(css.customerInfo)}>
          <img src={image['place.svg']} />
          <div className={classNames(css.text)}>〒{address.zipCode}</div>
        </div>
        <div className={classNames(css.address)}>
          {address.prefecture}
          {address.city}
          {address.suburb}
          {address.street}
          <br />
          {address.building}
        </div>
        <div className={classNames(css.address)}>{address.corporation}</div>
      </div>
      <div className={classNames(css.customerInfo)}>
        <img src={image['mail.svg']} />
        <span className={css.text}>{customer.mailAddress}</span>
      </div>
      <div className={classNames(css.customerInfo)}>
        <img src={image['phone.svg']} />
        <span className={css.text}>{customer.phoneNum}</span>
      </div>
    </div>
  );
}

export function DeliveryInfo_delivery(data) {
  const image = importAll(require.context('../delivery/confirm/image', false, /\.(webp|svg)$/));
  const address = data.address.list[data.address.selected];
  const delivery = data.delivery;
  if (!address) return null;
  return (
    <div className={css.customerInfoSection}>
      <div className={css.title}>配送先情報</div>
      <div className={classNames(css.customerInfo)}>
        <img src={image['customer.svg']} />
        <span className={css.text}>
          {address.lastName} {address.firstName}（{address.lastNameKana} {address.firstNameKana}）
        </span>
      </div>
      <div className={classNames(css.text)}>
        <div className={classNames(css.customerInfo)}>
          <img src={image['shipping.svg']} />
          <div className={classNames(css.text)}>〒{address.zipCode}</div>
        </div>
        <div className={classNames(css.address)}>
          {address.prefecture}
          {address.city}
          {address.suburb}
          {address.street}
          <br />
          {address.building}
        </div>
        <div className={classNames(css.address)}>{address.corporation}</div>
      </div>
      <div className={classNames(css.customerInfo)}>
        <img src={image['phone.svg']} />
        <span className={css.text}>{address.phoneNum}</span>
      </div>
      <div className={classNames(css.text)}>
        <div className={classNames(css.customerInfo)}>
          <img src={image['calender.svg']} />
          {/* <div className={classNames(css.text)}>{delivery.date && dayjs(delivery.date).format('YYYY年MM月DD日')}</div> */}
          <div className={classNames(css.text)}>
            {
              delivery.date &&
                (typeof delivery.date === 'string'
                  ? delivery.date // Display the string as is if delivery.date is a string
                  : dayjs(delivery.date).format('YYYY年MM月DD日')) // Format if it's a date object
            }
          </div>
        </div>
        <div className={classNames(css.address)}>{delivery.time}</div>
      </div>
    </div>
  );
}

export function DeliveryInfo_delivery_confirm(data) {
  const image = importAll(require.context('../delivery/confirm/image', false, /\.(webp|svg)$/));
  const address = data.address.list[data.address.selected];
  const delivery = data.delivery;
  if (!address) return null;

  return (
    <div className={css.customerInfoSection}>
      <div className={css.title}>配送先情報</div>
      <div className={classNames(css.customerInfo)}>
        <img src={image['customer.svg']} />
        <span className={css.text}>
          {address.lastName} {address.firstName}（{address.lastNameKana} {address.firstNameKana}）
        </span>
      </div>
      <div className={classNames(css.text)}>
        <div className={classNames(css.customerInfo)}>
          <img src={image['shipping.svg']} />
          <div className={classNames(css.text)}>〒{address.zipCode}</div>
        </div>
        <div className={classNames(css.address)}>
          {address.prefecture}
          {address.city}
          {address.suburb}
          {address.street}
          <br />
          {address.building}
        </div>
        <div className={classNames(css.address)}>{address.corporation}</div>
      </div>
      <div className={classNames(css.customerInfo)}>
        <img src={image['phone.svg']} />
        <span className={css.text}>{address.phoneNum}</span>
      </div>
      <div className={classNames(css.text)}>
        <div className={classNames(css.customerInfo)}>
          <img src={image['calender.svg']} />
          <div className={classNames(css.text)}>
            {
              delivery.date
                ? typeof delivery.date === 'object' && 'seconds' in delivery.date && 'nanoseconds' in delivery.date
                  ? dayjs(new Date(delivery.date.seconds * 1000 + delivery.date.nanoseconds / 1000000)).format(
                      'YYYY年MM月DD日'
                    ) // Format if it's a Date object
                  : '希望日指定なし' // Fallback if it's not a Date object
                : '希望日指定なし' // Fallback if delivery.date is null or undefined
            }
          </div>
        </div>
        <div className={classNames(css.address)}>{delivery.time}</div>
      </div>
    </div>
  );
}

export function OrderDetails(data) {
  const [productList, setProductList] = useState([]);
  const [deliveryFee, setDeliveryFee] = useState(0);
  const product = data.product;
  const total = data.total;
  useEffect(() => {
    const productList = [];
    let totalItemPrice = 0;

    if (Object.keys(product).length !== 0) {
      Object.keys(product).map((data, key) => {
        const item = product[data];
        if (item.count > 0) {
          totalItemPrice += item.count * item.price;
          productList.push(
            <div key={key} className={classNames(css.productList)}>
              <div className={classNames(css.productName, css.text)}>{item.name}</div>
              <div className={classNames(css.text, css.price)}>
                ¥{item.price.toLocaleString()} × {item.count} = ¥{(item.price * item.count).toLocaleString()}
              </div>
            </div>
          );
        }
      });
    }
    setDeliveryFee(total.totalPrice - totalItemPrice);
    setProductList(productList);
  }, []);

  return (
    <div className={css.orderDetailsSection}>
      <span className={css.title}>ご注文内容</span>
      {productList}
      <hr className={classNames(css.lineS)} />
      {deliveryFee > 0 && (
        <div className={classNames(css.productList)}>
          <div className={classNames(css.text)}>
            <span>配送料</span>
          </div>
          <div className={classNames(css.text, css.price)}>¥{Math.floor(deliveryFee).toLocaleString()}</div>
        </div>
      )}
      <div className={classNames(css.productList)}>
        <div className={classNames(css.text)}>
          <span>消費税（内税）</span>
        </div>
        <div className={classNames(css.text, css.price)}>
          ¥{Math.floor(total.totalPrice - total.totalPrice / 1.08).toLocaleString()}
        </div>
      </div>
      <hr className={classNames(css.lineS)} />

      <div className={classNames(css.productList)}>
        <span className={css.text}>合計</span>
        <span className={classNames(css.text, css.price)}>¥{total.totalPrice.toLocaleString()}</span>
      </div>
    </div>
  );
}
